import React from 'react'
import { Typography } from 'ui/atoms/Typography'
import { AboutInfoContainer, AboutInfoContent, AboutInfoHeader } from './index.styled'

const  AboutInfo = () =>  {
  return (
    <AboutInfoContainer>
        <AboutInfoHeader>
        <Typography variant='h1'>
            WHO WE ARE
            </Typography>
        </AboutInfoHeader>
        <AboutInfoContent>
           <div>
           <p>TACL Global is built on a strong foundation of values that guide our operations,
                including integrity, professionalism, excellence, innovation, teamwork, respect,
                and sustainability. We believe in creating long-lasting relationships with our
                esteemed clients globally by providing them with high-quality products and services,
                at great prices, sourced from reputable suppliers, and we ensure that they meet
                international standards. <br/><br/>
                We take pride in our experienced and dedicated team of
                experts and professionals who work tirelessly to ensure that our clients are satisfied
                with our products and services. Our team consists of experts in various fields
                and a network of renowned, influential and industry partners.</p>
                <br />
                <br />
                <p> Our employees are our most valuable asset, and we are committed to equipping
                    them with the right skills, knowledge and tools, as well as creating a conducive
                    working environment that fosters teamwork, innovation, and continuous learning
                    necessary to deliver the best results. We also prioritize the safety and wellbeing of
                    our employees, ensuring that they work in a safe and healthy environment.
                    <br/><br/> Our company is dedicated to creating value for our stakeholders, including our
                    clients, employees, shareholders, and the community at large. We are continuously
                    improving our operations and exploring new opportunities to serve our clients better.
                    We believe in transparency and accountability in our operations, and we are
                    committed to upholding the highest ethical standards in all our dealings.
                    <br/><br/> As a socially responsible company, TACL Global is committed to making a positive
                    impact on the communities in which we operate.</p>
           </div>
        </AboutInfoContent>
    </AboutInfoContainer>
  )
}

export default AboutInfo