import React from 'react'
import { CardContainer } from './index.styled'

interface CardProps {
    children: React.ReactNode;
}

const Card = (props: CardProps) => {
    const { children } = props;
  return (
   <CardContainer>
    {children}
   </CardContainer>
  )
}

export default Card