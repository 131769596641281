import styled from "styled-components";

export const ContantBannerContainer = styled.section`
    max-height:800px;
    width: 100%;
    position: relative;
    img{
        max-height: 800px;
        width: 100%;
    }

    @media (max-width: 990px){
        img{
            height: 70vh;
        }
      }

`;

export const BannerInfo = styled.div`
  width: 50%;
  text-align: left;
  position: absolute;
  z-index: 1;
  bottom: 150px;
  margin: 0 5rem;
  h1{
    font-size: ${({theme}) => theme.fontSizes["6xl"]};
    color: ${({theme}) => theme.colors.white};
    font-weight:  ${({theme}) => theme.fontWeights.extraBold};
    margin-bottom: 1rem;
  }
  @media (max-width: 1400px){
    width: 55%;
    h1{
        font-size: ${({theme}) => theme.fontSizes["5xl"]};
    }
  }
  @media (max-width: 1200px){
    width: 60%;
    bottom: 50px;
    h1{
        font-size: ${({theme}) => theme.fontSizes["5xl"]};
    }
  }
  @media (max-width: 990px){
    width: 70%;
    margin: 0 3rem;
    h1{
        font-size: ${({theme}) => theme.fontSizes["5xl"]};
    }
  }
  @media (max-width: 768px){
    width: 70%;
    bottom: 30px;
    h1{
        font-size: ${({theme}) => theme.fontSizes["4xl"]};
    }
  }
  @media (max-width: 480px){
    width: 70%;
    margin: 0 1rem;
  }
 `;

 export const BannerInfoTop = styled.div`
    margin-bottom: 7rem;
    p{
        font-weight: 400;
        font-size: 20px;
    }
    @media (max-width: 480px){
        p{
            font-size: 16px;
        }
    }
 `;

 export const BannerInfoBottom = styled.div`
  h4{
    margin-bottom: 1rem;
    font-weight: 500;
    font-size: 20px;
  }
  @media (max-width: 480px){
    h4{
        font-size: 14px;
    }
}
 `;