import React from 'react'
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import SwiperCore, { A11y, Autoplay, EffectCoverflow, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { BannerInfo } from './index.styled';
import ButtonLink from 'ui/components/ButtonLink';
import { ServicesCarouselContainer } from './index.styled'
import { Icon } from '@iconify/react';


SwiperCore.use([Pagination, A11y, Autoplay, EffectCoverflow]);

const ServicesCarousel = ():JSX.Element => {
  return (
   <ServicesCarouselContainer>
      <Swiper
        effect={'coverflow'}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: false
        }}
        spaceBetween={50}
        slidesPerView={1}
        pagination={{ clickable: true }}
        initialSlide={0}
        loop
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}>
        <SwiperSlide>
        <img className="image" src="/uploads/Metal1.png" alt="img" />
          <BannerInfo>
            <h1>MINOR METALS</h1>
            <ButtonLink href='/minor-metals'>
            <Icon icon="uil:angle-right-b" color="white" width="24" />
             <p> Read More</p>
            </ButtonLink>
            </BannerInfo>
        </SwiperSlide>
        <SwiperSlide>
          <img className="image" src="/uploads/Metal2.png" alt="img" />
          <BannerInfo>
            <h1>BASE METAL</h1>
            <ButtonLink href='/base-metals'>
            <Icon icon="uil:angle-right-b" color="white" width="24" />
             <p> Read More</p>
            </ButtonLink>
            </BannerInfo>
        </SwiperSlide>
        <SwiperSlide>
          <img className="image" src="uploads/Metal3.png" alt="img" />
          <BannerInfo>
            <h1>AGRO COMMODITIES </h1>
            <ButtonLink href='/agro-commodities'>
            <Icon icon="uil:angle-right-b" color="white" width="24" />
             <p> Read More</p>
            </ButtonLink>
            </BannerInfo>
        </SwiperSlide>
        .
      </Swiper>
   </ServicesCarouselContainer>
  )
}

export default ServicesCarousel