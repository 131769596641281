import React from 'react'
import ValueCard from 'ui/components/ValueCard';
import { ValuesHeader } from '../Company/index.styled';
import { AboutValueContainer, AboutValueWrapper, ValueCardImage, ValueCardsContainer } from './index.styled'

const AboutValue = () => {
  return (
   <AboutValueContainer>
    <ValuesHeader>COMPANY VALUES</ValuesHeader>
    <AboutValueWrapper>
        <ValueCardsContainer>
        <ValueCard valueNote='Honesty, integrity, professionalism and ethical conduct' />
        <ValueCard valueNote='Customer satisfaction, service excellence, excellent and complete
performance of contracts' />
        <ValueCard valueNote='Commitment to quality, health and safety of products, employees,
customers and stakeholders' />
        <ValueCard valueNote='Sustainability, environmental and social responsibility' />
        <ValueCard valueNote='Innovation and continuous improvement' />
        <ValueCard valueNote='Teamwork and collaboration' />
        <ValueCard valueNote='Respect, accountability and responsibilityn' />
        </ValueCardsContainer>
        <ValueCardImage>
            <img src="/uploads/Logistics.png" alt="img" />
        </ValueCardImage>
    </AboutValueWrapper>
    
   </AboutValueContainer>
  )
}

export default AboutValue