import React from 'react'
import { Typography } from 'ui/atoms/Typography'
import { ProfileInfoHeader } from '../ProfileInfo/index.styled'
import { MetalGallery, MetalInfoContainer } from './index.styled'

interface MetalInfoProps {
  title: string;
  info: string;
  children: React.ReactNode;
}

const MetalInfo = (props: MetalInfoProps) => {
const { title,info, children }= props;

  return (
    <MetalInfoContainer>
      <ProfileInfoHeader>
      <Typography variant='h1'>
      {title}
            </Typography>
            <p> {info} </p>
      </ProfileInfoHeader>
      <MetalGallery>
        {children}
      </MetalGallery>
    </MetalInfoContainer>
  )
}

export default MetalInfo