import React from 'react'
import { BannerInfo, BannerInfoBottom, BannerInfoTop, ContantBannerContainer } from './index.styled'

const ContactBanner = ():JSX.Element => {
  return (
   <ContantBannerContainer>
    <img src="/uploads/ContactBanner.png" alt="banner" />
    <BannerInfo>
        <BannerInfoTop>
            <h1>Contact Us</h1>
            <p>We’d love to learn more about how we can partner with your organization. Tell us a bit about yourself, and we’ll get in touch as soon as we can.</p>
        </BannerInfoTop>
        <BannerInfoBottom>
            <h4>Mail Us: Taclglobal@gmail.com</h4>
        </BannerInfoBottom>
    </BannerInfo>
   </ContantBannerContainer>
  )
}

export default ContactBanner