import { Route, Routes } from 'react-router-dom';
import Landing from 'pages/Landing';
import NotFound from 'pages/NotFound';
import Contact from 'pages/Contact';
import About from 'pages/About';
import Profile from 'pages/Profile';
import Minor from 'pages/Minor';
import Base from 'pages/Base';
import Agro from 'pages/Agro';

const App = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/about" element={<About />} />
      <Route path="/our-ethos" element={<Profile />} />
      <Route path="/minor-metals" element={<Minor/>} />
      <Route path="/base-metals" element={<Base />} />
      <Route path="/agro-commodities" element={<Agro />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
