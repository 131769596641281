import styled from "styled-components";

export const DropdownContentContainer = styled.div`
    width: 200px;
    background-color: black;
    border-radius: 10px;
    display: none;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding:1rem;
    position:absolute;
    top: 30px;
    margin-left:auto;
    margin-right: auto;
    z-index: 10000;
    a{
        font-size: 14px;
        margin-bottom: 0.7rem;
    }
    @media (max-width: 768px){
        border: 1px solid white;
    }
`;