import React from 'react'
import { CustomHeroContainer, BannerInfo, BannerInfoTop } from './index.styled'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
interface CustomHeroProps {
    src: string;
    bannerTitle: string;
    bannerNote: string;
    className?: string;
    className2?: string;
}

const CustomHero = (props: CustomHeroProps) => {
    const{ src, bannerTitle, bannerNote, className, className2} = props;
  return (
   <CustomHeroContainer >
     <LazyLoadImage src={src} alt="banner" effect='blur' width='100%' placeholderSrc="/uploads/CHP.png"/>
    <BannerInfo className={className2}>
        <BannerInfoTop className={className}>
            <h1>{bannerTitle}</h1>
            <p >{bannerNote} </p>
        </BannerInfoTop>
    </BannerInfo>
   </CustomHeroContainer>
  )
}

export default CustomHero