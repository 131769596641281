import React, { useState } from 'react'
import CustomHero from 'ui/components/CustomHero'
import MobileNav from 'ui/components/MobileNav'
import Nav from 'ui/components/Nav'
import Footer from 'ui/widgets/Footer'
import MetalInfo from 'ui/widgets/MetalInfo'
import { BaseContainer } from './index.styled'

const Base = () => {
  const [open, setOpen] = useState(false);
  return (
   <BaseContainer>
   {!open && <Nav setOpen={setOpen} open={open} />}
      <MobileNav open={open} setOpen={setOpen} />
    <CustomHero  className='metal-hero'  src="/uploads/BaseBanner.png" bannerTitle="BASE METALS" bannerNote=""/>
    <MetalInfo title="BASE METALS" info='Where our customers have custom-specifications on any of our products, we undertake an elaborate process to ensure that the products are delivered promptly and in accordance with the details of the specifications.'>
  <img src="/uploads/Led.png" alt="" />
  <img src="/uploads/Tin.png" alt="" />
</MetalInfo>
<Footer />
    </BaseContainer>
  )
}

export default Base