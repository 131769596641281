import React from 'react'
import { Link } from 'react-router-dom'
import { Icon } from '@iconify/react';
import { FooterBottom, FooterBottomDown, FooterBottomHR, FooterContainer, FooterRightLinks, FooterRightSocials, FooterTop, FooterTopLogo, FooterTopRight, SocialLinks } from './index.styled'

const Footer = ():JSX.Element => {
  return (
    <FooterContainer>
        <FooterTop>
            <FooterTopLogo>
                <img src="/uploads/BlackLogo.png" alt="logo" />
            </FooterTopLogo>
            <FooterTopRight>
                <FooterRightLinks>
                    <div>
                        <Link to={'/about'}> About Us</Link>
                        <Link to={'/our-ethos'}> Our Ethos</Link>
                    </div>
                    <div>
                        <Link to={'/'}>Products </Link>
                        <Link to={'/contact'}> Contact Us </Link>
                    </div>
                </FooterRightLinks>
                <FooterRightSocials>
                    <h3>GET TO KNOW US</h3>
                   <SocialLinks>
                    <a href={'/'}>
                    <Icon icon="uil:whatsapp-alt"  width="30"/>
                    </a>
                    <a href={'/'}>
                    <Icon icon="uil:instagram-alt"  width="30"/>
                    </a>
                    <a href={'/'}>
                    <Icon icon="mdi:gmail" color="black" width="30" />
                    </a>
                   </SocialLinks>
                </FooterRightSocials>
            </FooterTopRight>
        </FooterTop>
        <FooterBottom>
            <FooterBottomHR></FooterBottomHR>
            <FooterBottomDown>
                <Link to={'/'}>Privacy Policy | Terms & Conditions</Link>
            </FooterBottomDown>
        </FooterBottom>
    </FooterContainer>
  )
}

export default Footer