import styled from 'styled-components';

export const ButtonLinkContainer = styled.a`
  display: flex;
  align-items: center;
  gap: 5px;
  background: ${({theme}) => theme.colors.secondary};
  border-radius: 11px;
  padding: 0.7rem 2rem;
  color: ${({theme}) => theme.colors.white};
  border: none;
  cursor: pointer;
  white-space: nowrap;
  font-weight: ${({theme}) => theme.fontWeights.semiBold};
  width: fit-content;
  font-size: ${({theme}) => theme.fontSizes.sm};
  svg {
    width: 25px;
    height: 25px;
  }
`;