import styled from "styled-components";

export const ProfileInfoContainer= styled.section`
    width: 100%;
    padding:3rem 5rem 2rem;
    background-color: white;

    @media (max-width: 990px){
    padding:3rem 3rem 2rem;
    }
    @media (max-width: 480px){
    padding:3rem 1rem 2rem;
    }
`;

export const ProfileInfoHeader = styled.div`
width: 100%;
background-color: ${({theme}) => theme.colors.white};
text-align: center;
padding: 0 0 2rem;
h1{
    text-transform: uppercase;
    color: ${({theme}) => theme.colors.primary};
    padding: 15rem 0 3rem;
    font-weight:  ${({theme}) => theme.fontWeights.extraBold};
    font-size:  ${({theme}) => theme.fontSizes["5xl"]};
    position: relative;
    &::before{
        content: '';
        position: absolute;
        bottom: 40px;
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto; 
        width: 150px;
        height: 10px;
        background-color: ${({theme}) => theme.colors.secondary};
        border-radius: 33px;
    }
}

p{
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: black;
    width: 70%;
    margin: auto;
}
@media (max-width: 1400px){
    p{
        width: 100%;
    }
}

@media (max-width: 990px){
    p{
        width: 100%;
    }
}

@media (max-width: 480px){
    h1{
        padding: 8rem 0 3rem;
        font-size:  ${({theme}) => theme.fontSizes["4xl"]};
        &::before{
            width: 150px;
        }
    }
}
`;

