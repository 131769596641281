import React, { useState } from 'react'
import CustomHero from 'ui/components/CustomHero'
import MobileNav from 'ui/components/MobileNav'
import Nav from 'ui/components/Nav'
import Footer from 'ui/widgets/Footer'
import MetalInfo from 'ui/widgets/MetalInfo'
import { MinorContainer } from './index.styled'

const Minor = () => {
  const [open, setOpen] = useState(false)
  return (
   <MinorContainer>
     {!open && <Nav setOpen={setOpen} open={open} />}
      <MobileNav open={open}  setOpen={setOpen}/>
    <CustomHero className='metal-hero' src="/uploads/MinorBanner.png" bannerTitle="MINOR METALS" bannerNote=""/>
    <MetalInfo title="MINOR METALS" info='In addition to our internal quality control measures, we work with globally recognized independent Quality Control experts to conduct rigorous checks and test on all products before shipment to ensure that they meet our customers specifications.'>
  <img src="/uploads/Tantalite.png" alt="" />
</MetalInfo>
    <Footer/>
   </MinorContainer>
  )
}

export default Minor