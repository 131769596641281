import { useState } from "react";
import CustomHero from "ui/components/CustomHero";
import MobileNav from "ui/components/MobileNav";
import Nav from "ui/components/Nav";
import Statement from "ui/components/Statement";
import AboutInfo from "ui/widgets/AboutInfo";
import AboutValue from "ui/widgets/AboutValues";
import { CompanyContainer } from "ui/widgets/Company/index.styled";
import Footer from "ui/widgets/Footer";
import { AboutContainer } from "./index.styled";

const About = (): JSX.Element => {
    const [open, setOpen] = useState(false)
    return (
        <AboutContainer>
            {!open && <Nav setOpen={setOpen} open={open} />}
      <MobileNav open={open} setOpen={setOpen}/>
           <CustomHero src="/uploads/AboutBanner.png" bannerTitle="About Us" bannerNote="TACL Global is a commodities trading company which specializes in the export of high-quality agro commodities, minor metals, and base metals."/>
           <AboutInfo />
           <CompanyContainer>
           <Statement className='mision' src='/uploads/Frame1.png' title='Our Mission' note='Our mission is to provide our customers with ethically and sustainably sourced, high-quality agro-commodities,
            minor metals and base metals at competitive prices, while
            operating with integrity and adhering to the highest standards
            of ethics and corporate responsibility. We are committed to
            creating a safe and fair workplace, delivering great value to
            our customers and stakeholders, promoting environmental
            stewardship, respecting human rights, complying with all
            applicable laws and regulations, and contributing to the
            development of the communities where we operate.'/>
            <Statement className='vision' src='/uploads/Frame2.png' title='Our Vision' note='Our vision is to be a leading, reputable and socially responsible
global exporter of agro commodities, minor metals and base
metals that is recognized for its excellent and complete
performance of contracts, commitment to sustainable sourcing,
quality products, great prices, ethical business practices and
corporate social responsibility.'/>
           </CompanyContainer>
           <AboutValue />
            <Footer />
        </AboutContainer>
    )
};

export default About;