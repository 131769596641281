import React from 'react'
import { DropdownContentContainer } from './index.styled'

interface DropdownProps {
    children: React.ReactNode;
}

const CustomDropdownContent = (props: DropdownProps):JSX.Element => {
    const {children } = props;
  return (
    <DropdownContentContainer>
        {children}
    </DropdownContentContainer>
  )
}

export default CustomDropdownContent;