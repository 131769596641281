import styled from "styled-components";

export const ProfileContainer = styled.div`
    width: 100%;
    position: relative;
   @media(max-width: 1200px){
   .ethos-banner2{
    bottom: 0;
    width: 70%;
   }
   }
   @media(max-width: 990px){
   .ethos-banner2{
    bottom: 0;
    width: 80%;
    .ethos-banner{
        margin-bottom: 5rem;
        p{
            font-size: 14px;
        }
    }
   }
   }

   @media(max-width: 480px){
    .ethos-banner{
        margin: 0;
        p{
            font-size: 14px;
        }
    }
   }
   @media(max-width: 400px){
   .ethos-banner2{
    .ethos-banner{
        margin-bottom: 1rem;
        p{
            font-size: 11px;
        }
        h1{
            font-size: 24px;
        }
    }
   }
   }
`;

export const ProfileDivider = styled.div`
    width: 100%;
    background: #EEEEEE;
    height: 305px;
`;