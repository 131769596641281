import styled from "styled-components";

export const ValueCardContainer = styled.div`
    display: flex;
    align-items:center;
    gap: 10px;
    background-color:white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    padding: 10px 20px;
    width: 100%;
    div{
        width: 11px;
        height: 28px;
        background: #CE9308;
    }
    p{
        color: #1E1E1E;
        font-weight: 700;
        font-size: 16px;
        width: 100%;
    }
    @media (max-width: 768px){
     p{
        font-size: 14px;
     }
    }
`;