import React from 'react'
import { useLocation } from 'react-router-dom'
import { LinksItem, MobileNavContainer, NavButtonLink } from './index.styled'
import { Icon } from '@iconify/react';
import CustomDropdown from '../Dropdown';
import CustomDropdownContent from '../DropdownContent';


const MobileNav = ({ open, setOpen }):JSX.Element => {
  const CustomLink = ({ href, children }) => {
    const location = useLocation();
    const active = location.pathname === href ? true : false;
    return (
      <LinksItem href={href} active={active}>
        {children}
      </LinksItem>
    );
  };
  return (
    <MobileNavContainer className={open ? 'open' : ''}>
       <Icon icon="ic:baseline-close" color="white" onClick={() => setOpen(false)} className='close' width={30}/>
       <CustomLink href="/about">About Us</CustomLink>
       <CustomLink href="/our-ethos">Our Ethos</CustomLink>
       <CustomDropdown>
        <p>Products</p>
        <Icon icon="prime:angle-down" color="white" width="34" />
        <CustomDropdownContent>
          <CustomLink href={'/agro-commodities'}> Agro Commodities</CustomLink>
        <CustomLink href="/minor-metals">Minor Metals</CustomLink>
          <CustomLink href={'/base-metals'}> Base Metals</CustomLink>
        </CustomDropdownContent>
       </CustomDropdown>
       <CustomDropdown>
        <p>Investors Relations</p>
        <Icon icon="prime:angle-down" color="white" width="34" />
        <CustomDropdownContent>
          <p>Coming soon</p>
        </CustomDropdownContent>
       </CustomDropdown>
       <NavButtonLink href='/contact'> Contact Us</NavButtonLink>
    </MobileNavContainer>
  )
}

export default MobileNav