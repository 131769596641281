import styled from "styled-components";

export const CompanyContainer = styled.section`
    width: 100%;
    background-color: ${({theme}) => theme.colors.white};
    padding: 3rem 0 0;
   
`;

export const ValuesContainer = styled.div`
     width: 100%;
     padding: 5rem ;
     background: #EEEEEE;
     a{
        margin: auto;
        border-radius: 33px;
     }
     @media (max-width: 990px){
        padding: 5rem 3rem;
   }
     @media (max-width: 480px){
        padding: 5rem 1rem;
   }
`;

export const ValuesHeader = styled.h1`
    color: #D9D9D9;
    font-size: ${({theme}) => theme.fontSizes["5xl"]};
    font-weight: ${({theme}) => theme.fontWeights.bold};
    @media (max-width: 480px){
        font-size: ${({theme}) => theme.fontSizes["4xl"]};
   }
`;

export const ValuesGrid = styled.div`
    display: flex;
    align-items: center;
    gap: 30px;
    max-width: fit-content;
    width: 100%;
    margin: 2rem auto;
    overflow-x: auto;
    div {
        border-radius: 15px;
        min-width: 356px;
        img{
            border-radius: 15px;
        }
    }
   
`;


