import React, { useState } from 'react'
import CustomHero from 'ui/components/CustomHero'
import MobileNav from 'ui/components/MobileNav'
import Nav from 'ui/components/Nav'
import Footer from 'ui/widgets/Footer'
import MetalInfo from 'ui/widgets/MetalInfo'
import { AgroContainer } from './index.styled'

const Agro = () => {
  const [open, setOpen] = useState(false)
  return (
   <AgroContainer>
      {!open && <Nav setOpen={setOpen} open={open} />}
      <MobileNav open={open} setOpen={setOpen}/>
    <CustomHero  className='metal-hero'  src="/uploads/AgroBanner.png" bannerTitle="AGRO COMMODITIES" bannerNote=""/>
    <MetalInfo title="AGRO COMMODOITIES" info='At TACL Global, we place a high premium on quality. We work with trusted suppliers and partners to ensure that our products are of very high quality.'>
  .<img src="/uploads/Ginger.png" alt=""/>
  .<img src="/uploads/Cashew.png" alt=""/>
  .<img src="/uploads/Hibiscus.png" alt=""/>
  .<img src="/uploads/Orange.png" alt=""/>
  .<img src="/uploads/Sesame.png" alt=""/>
</MetalInfo> 
    <Footer/>
   </AgroContainer>
  )
}

export default Agro