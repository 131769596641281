import styled from "styled-components";

export const MetalInfoContainer = styled.div`
width: 100%;
padding:3rem 5rem 2rem;
background-color: white;

@media (max-width: 990px){
padding:3rem 3rem 2rem;
}
@media (max-width: 480px){
padding:3rem 1rem 2rem;
}
`;

export const MetalGallery = styled.div`
    display; flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align:center;
    img{
       width: 450px;
       height: 450px;
       border-radius: 33px;
       margin: 5rem 3rem;
    }

    @media (max-width: 768px){
        img{
            margin: 4rem auto;
        }
    }
`;