import React from 'react'
import { ButtonLinkContainer } from './index.styled';

interface ButtonLinkProps {
    children: React.ReactNode;
    href?: string;
    className?: string;
}

const ButtonLink = (props: ButtonLinkProps): JSX.Element => {
    const {children, href, className} = props;
  return (
    <ButtonLinkContainer href={href} className={className}> {children} </ButtonLinkContainer>
  )
}

export default ButtonLink