import { useState } from "react";
import MobileNav from "ui/components/MobileNav";
import Nav from "ui/components/Nav";
import Company from "ui/widgets/Company";
import Footer from "ui/widgets/Footer";
import Hero from "ui/widgets/Hero";
import Services from "ui/widgets/Services";
import { LandingContainer } from "./index.styled";

const Landing = (): JSX.Element => {
    const [open, setOpen] = useState(false)
    return (
        <LandingContainer>
            {!open && <Nav setOpen={setOpen} open={open} />}
      <MobileNav open={open} setOpen={setOpen} />
            <Hero />
            <Services />
            <Company />
            <Footer />
        </LandingContainer>
    )
};

export default Landing;