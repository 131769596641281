import styled from "styled-components";

export const AboutInfoContainer = styled.section`
width:100%;
background: white; 
`;

export const AboutInfoHeader = styled.div`
width: 100%;
background-color: ${({theme}) => theme.colors.white};
text-align: center;
padding: 0 5rem;
h1{
    color: ${({theme}) => theme.colors.primary};
    padding: 15rem 0 3rem;
    font-weight:  ${({theme}) => theme.fontWeights.extraBold};
    font-size:  ${({theme}) => theme.fontSizes["5xl"]};
    position: relative;
    &::before{
        content: '';
        position: absolute;
        bottom: 40px;
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto; 
        width: 150px;
        height: 10px;
        background-color: ${({theme}) => theme.colors.secondary};
        border-radius: 33px;
    }
}
@media (max-width: 990px){
    padding: 0 3rem;
}
@media (max-width: 480px){
    padding: 0 1rem;
    h1{
        padding: 8rem 0 3rem;
        font-size:  ${({theme}) => theme.fontSizes["4xl"]};
        &::before{
            width: 150px;
        }
    }
}
`

export const AboutInfoContent = styled.div`
text-align: center;
padding: 2rem 5rem;
background-color: #EEEEEE;
color: black;
div{
    max-width: 1000px;
    margin: 0 auto;
    p{
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
    }
}

@media (max-width: 990px){
    padding: 2rem 3rem;
}
@media (max-width: 480px){
    padding: 2rem 1rem;
    div{
        p{
            font-size: 16px;
        }
    }
}
`