import React from 'react'
import ButtonLink from '../ButtonLink'
import { StatementContainer, StatementContent, StatementImage, StatementNote, StatementTitle } from './index.styled'

interface StatementProps {
    title: string;
    note: string;
    src: string;
    className?: string;
}
 const Statement = (props: StatementProps) => {
    const { title, src, note, className} = props;
  return (
   <StatementContainer className={className}>
    <StatementImage src={src} alt='mission-img'/>
    <StatementContent>
        <StatementTitle>{title}</StatementTitle>
        <StatementNote> {note}</StatementNote>
        <ButtonLink href='/about' className='about-btn'>Read More</ButtonLink>
    </StatementContent>
   </StatementContainer>

  )
}

export default Statement