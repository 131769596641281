import React from 'react'
import { CustomButtonContainer } from './index.styled';

interface CustomButtonProps {
    children: React.ReactNode;
    onClick?: () => void;
    type?: 'button' | 'submit' | 'reset';
    disabled?: boolean;
}

const CustomButton = (props: CustomButtonProps): JSX.Element => {
    const {children, onClick, type, disabled} = props;
  return (
    <CustomButtonContainer type={type} onClick={onClick} disabled={disabled}> {children} </CustomButtonContainer>
  )
}

export default CustomButton