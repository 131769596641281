import { useState } from "react";
import MobileNav from "ui/components/MobileNav";
import Nav from "ui/components/Nav";
import ContactBanner from "ui/widgets/ContactBanner";
import ContactForm from "ui/widgets/ContactForm";
import Footer from "ui/widgets/Footer";
import { ContactContainer } from "./index.styled";

const Contact = (): JSX.Element => {
    const [open, setOpen] = useState(false)
    return (
        <ContactContainer>
           {!open && <Nav setOpen={setOpen} open={open} />}
      <MobileNav open={open}  setOpen={setOpen}/>
           <ContactBanner />
           <ContactForm />
            <Footer />
        </ContactContainer>
    )
};

export default Contact;