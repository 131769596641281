import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { NavContainer, NavButtonLink, NavLinks, NavLogo, LinksItem} from './index.styled'
import { Icon } from '@iconify/react';
import CustomDropdown from '../Dropdown';
import CustomDropdownContent from '../DropdownContent';
const Nav = ({setOpen, open}):JSX.Element => {
  const CustomLink = ({ href, children }) => {
    const location = useLocation();
    const active = location.pathname === href ? true : false;
    return (
      <LinksItem href={href} active={active}>
        {children}
      </LinksItem>
    );
  };
  return (
    <NavContainer>
    <Link to={'/'}>
        <NavLogo src='/uploads/WhiteLogo.png' alt='logo'/>
    </Link>
    <NavLinks>
    <CustomLink href={'/about'}> About Us</CustomLink>
      <CustomLink href="/our-ethos">Our Ethos</CustomLink>
       <CustomDropdown>
        <p>Products</p>
        <Icon icon="prime:angle-down" color="white" width="34" />
        <CustomDropdownContent>
          <CustomLink href={'/agro-commodities'}> Agro Commodities</CustomLink>
        <CustomLink href="/minor-metals">Minor Metals</CustomLink>
          <CustomLink href={'/base-metals'}> Base Metals</CustomLink>
        </CustomDropdownContent>
       </CustomDropdown>
       <CustomDropdown>
        <p>Investors Relations</p>
        <Icon icon="prime:angle-down" color="white" width="34" />
        <CustomDropdownContent>
          <p>Coming soon</p>
        </CustomDropdownContent>
       </CustomDropdown>
       <NavButtonLink href='/contact'> Contact Us</NavButtonLink>
       <div onClick={() => {setOpen(true)}} className='menu'> <Icon icon="ic:round-menu"  width="34" /></div>
    </NavLinks>
    </NavContainer>
  )
}

export default Nav