import styled from 'styled-components';

export const MobileNavContainer = styled.header`
    width: 0;
    max-height: 100vh;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -200px;
    z-index: ${({theme}) => theme.zIndices.sticky};
    padding: 1.5rem 5rem;
    background: black;
    transition: width 350ms ease-in-out;
    &.open {
        width: 100%;
        left:0;
      }
    a,div{
        font-size: ${({theme}) => theme.fontSizes.md};
        margin-bottom: 1rem;
    }
    .close{
        margin-left: auto;
        margin-bottom: 30vw;
    }
    @media (max-width: 1400px){
        padding: 1.5rem 4rem;
    }
    @media (max-width: 990px){
        padding: 1.5rem 3rem;
    }
    @media (max-width: 768px){
       display: flex;
    }
    @media (max-width: 480px){
        padding: 1.5rem 1rem;
    }
`;

export const NavLinkDropdown= styled.li``;
export const NavButtonLink = styled.a`
background: transparent;
border: 3px solid #FFFFFF;
border-radius: 11px;
padding: 10px 25px;
color: ${({theme}) => theme.colors.white};
cursor: pointer;
white-space: nowrap;
width: fit-content;
font-size: ${({theme}) => theme.fontSizes.sm};
@media (max-width: 990px){
    padding: 8px 18px;
    border: 2px solid #FFFFFF;
}
`;

export const LinksItem = styled.a<{ active: boolean }>`
  text-transform: capitalize;
  font-weight: ${({ active }) => (active ? '700' : '400')};
  color: ${({ active }) => (active ? '#CE9308' : 'white')};
`;