import styled from 'styled-components';

export const NavContainer = styled.header`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0;
    z-index: ${({theme}) => theme.zIndices.sticky};
    padding: 1.5rem 5rem;
    background: transparent;
    @media (max-width: 1400px){
        padding: 1.5rem 4rem;
    }
    @media (max-width: 990px){
        padding: 1.5rem 3rem;
    }
    @media (max-width: 768px){
        background: black;
    }
    @media (max-width: 480px){
        padding: 1.5rem 1rem;
    }
`;
export const NavLogo = styled.img`
    width: 100px;
`;
export const NavLinks = styled.ul`
    display: flex;
    align-items: center;
    gap: 22px;
    a{
        &:hover{
            opacity: 0.7;
           }
    }
    .menu{
        display: none;
        cursor: pointer;
    }
    @media (max-width: 990px){
        gap: 16px;
        a,div{
            font-size: ${({theme}) => theme.fontSizes.sm};
        }
    }
    @media (max-width: 800px){
        gap: 10px;
        a,div{
            font-size: ${({theme}) => theme.fontSizes.sm};
        }
    }
    @media (max-width: 768px){
        a,div{
           display: none;
        }
     .menu{
        display: inline-flex;
    }
    }
`;
export const LinksItem = styled.a<{ active: boolean }>`
  text-transform: capitalize;
  font-weight: ${({ active }) => (active ? '700' : '400')};
  color: ${({ active }) => (active ? '#CE9308' : 'white')};
`;
export const NavButtonLink = styled.a`
background: transparent;
border: 3px solid #FFFFFF;
border-radius: 11px;
padding: 10px 25px;
color: ${({theme}) => theme.colors.white};
cursor: pointer;
white-space: nowrap;
width: fit-content;
font-size: ${({theme}) => theme.fontSizes.sm};
&:hover{
    opacity: 0.7;
   }
@media (max-width: 990px){
    padding: 8px 18px;
    border: 2px solid #FFFFFF;
}
`;