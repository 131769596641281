import styled from "styled-components";

export const ServicesCarouselContainer = styled.div`
    height: 600px;
    background-color: ${({theme}) => theme.colors.white};
    width: 100%;
    .swiper{
        .swiper-slide-active {
            height: 100%;
            position: relative;
            .image {
            opacity: 1;
            width: 100%;
            max-height: 470px;
            }
        }
        .swiper-wrapper {
            margin-bottom: 2rem;
        }
        .swiper-pagination{
            .swiper-pagination-bullet {
                background-color: ${({theme}) => theme.colors.black};
                width: 50px;
                border-radius: 33px;
                opacity: 1;
            }
            .swiper-pagination-bullet-active {
            background-color: ${({theme}) => theme.colors.secondary};
            }
        }
    }

    @media (max-width: 990px){
        .swiper{
            .swiper-slide-active {
                .image {
                    height: 40vh;
                }
            }
    }
    @media (max-width: 768px){
       height: 55vh;
       .swiper{
        .swiper-slide-active {
            text-align:center;
            .image {
                width: 90%;
                border-radius:10px;
            }
        }
    }
    @media (max-width: 480px){
       height: 55vh;
       .swiper{
        .swiper-slide-active {
            text-align:center;
            .image {
                width: 90%;
                border-radius:10px;
            }
        }
    }
    `;

    export const BannerInfo = styled.div`
    width: 70%;
    text-align: left;
    position: absolute;
    z-index: 1;
    bottom: 180px;
    margin: 0 5rem;
    h1{
      font-size: ${({theme}) => theme.fontSizes["6xl"]};
      color: ${({theme}) => theme.colors.white};
      margin-bottom: ${({theme}) => theme.sizes[4]};
    }
    a{
        background-color: transparent;
        border: 3px solid #FFFFFF;
        border-radius: 30px;     
        svg{
            display:none;
        }
    }
    @media (max-width: 990px){
      bottom: 100px;
      margin: 0 3rem;
    }
    @media (max-width: 768px){
        bottom: 20px;
        width: 80%;
        text-align: center;
      h1{
        font-size: ${({theme}) => theme.fontSizes["4xl"]};
      }
      a{
        padding: 0.3rem 1rem;
        margin: auto;
        background-color:${({theme}) => theme.colors.secondary};
        border: none;
        
      svg{
        display: inline;
      }
    }
    }
    @media (max-width: 480px){
        bottom: 20px;
        width: 80%;
        text-align: center;
        h1{
            font-size: ${({theme}) => theme.fontSizes["3xl"]};
            margin: 1rem auto;
        }
        a{
            padding: 0.3rem 1rem;
            margin: auto;
            background-color:${({theme}) => theme.colors.secondary};
            border: none;
            
          svg{
            display: inline;
          }
        }
      }
    `;