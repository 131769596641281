import React, { useState } from 'react'
import axios from 'axios'
import { ContactFormContainer, Form, InputGroup  } from './index.styled'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Card from 'ui/components/Card'
import CustomButton from 'ui/components/CustomButton'
import { Icon } from '@iconify/react'


const ContactForm = () => {
    const phoneRegExp = /^[/+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,8}$/
    const [isLoading, setisLoading] = useState(false);
    const [success, setSuccessMessage] = useState('')
    const formik = useFormik({
        initialValues: {
          firstName: '',
          lastName: '',
          email: '',
          phone:'',
          company:'',
          message: '',
        },
        validationSchema: Yup.object({
          firstName: Yup.string()
                  .label('First Name')
                  .required(),
          lastName: Yup.string()
                  .label('Last Name')
                  .required(),
          email: Yup.string()
                  .email()
                  .label('Email')
                  .required(),
          phone: Yup.string()
                  .matches(phoneRegExp, 'Phone number is not valid')
                  .label('Phone Number')
                  .required(),
          company: Yup.string()
                  .label('Company')
                  .required(),
          message: Yup.string()
                  .label('Your Message')
                  .required(),
        }),
        onSubmit: function  (values, {resetForm}) {
          setisLoading(true)
  
           axios.post(' https://tacl-api.onrender.com/api/utils/contact-us', values)
          .then(function (response) {
            setisLoading(false);
            setSuccessMessage("Message Sent.")
            setTimeout(() => {
              setSuccessMessage("")
            },5000)
          })
          .catch(function (error) {
            setisLoading(false);
            setSuccessMessage("Message Failed, Please Try Again.")
            setTimeout(() => {
              setSuccessMessage("")
            },5000)
          });
           resetForm();
        }
    })
  return (
    <ContactFormContainer>
      <Card>
        <Form onSubmit={formik.handleSubmit}>
          <h4>{success}</h4>
       <InputGroup>
       <input
        className={formik.touched.firstName && formik.errors.firstName ? 'error' : ''}
        onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.firstName}
         type="text" 
         name="firstName"
         id="firstName"
         placeholder="First Name*"
         />
          {formik.touched.firstName && formik.errors.firstName && (
           <span className='errorText'>{formik.errors.firstName}</span>
           )}
           </InputGroup>
       <InputGroup>
       <input
        className={formik.touched.lastName && formik.errors.lastName ? 'error' : ''}
        onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.lastName}
         type="text" 
         name="lastName"
         id="lastName"
         placeholder="Last Name*"
         />
          {formik.touched.lastName && formik.errors.lastName && (
           <span className='errorText'>{formik.errors.lastName}</span>
           )}
           </InputGroup>
       <InputGroup>
       <input
        className={formik.touched.email && formik.errors.email ? 'error' : ''}
        onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email}
         type="email" 
         name="email"
         id="email"
         placeholder="Email*"
         />
          {formik.touched.email && formik.errors.email && (
           <span className='errorText'>{formik.errors.email}</span>
           )}
           </InputGroup>
       <InputGroup>
       <input
        className={formik.touched.phone && formik.errors.phone ? 'error' : ''}
        onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.phone}
         type="text" 
         name="phone"
         id="phone"
         placeholder="Mobile*"
         />
          {formik.touched.phone && formik.errors.phone && (
           <span className='errorText'>{formik.errors.phone}</span>
           )}
           </InputGroup>
       <InputGroup>
       <input
        className={formik.touched.company && formik.errors.company ? 'error' : ''}
        onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.company}
         type="text" 
         name="company"
         id="company"
         placeholder="Company*"
         />
          {formik.touched.company && formik.errors.company && (
           <span className='errorText'>{formik.errors.company}</span>
           )}
           </InputGroup>
       <InputGroup>
       <textarea
        className={formik.touched.message && formik.errors.message ? 'error' : ''}
        onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.message}
         name="message"
         id="message"
         placeholder="Message*"
         rows={5}
         />
          {formik.touched.message && formik.errors.message && (
           <span className='errorText'>{formik.errors.message}</span>
           )}
           </InputGroup>
           <CustomButton type='submit'> 
           {isLoading ? <Icon icon="eos-icons:bubble-loading" color="white" /> :  <Icon icon="uil:angle-right-b" color="white" width="20" />}
           {isLoading ? <p> Sending </p> : <p> Submit</p>}
           </CustomButton>
        </Form>
      </Card>
    </ContactFormContainer>
  )
}

export default ContactForm