import React from 'react'
import { ValueCardContainer } from './index.styled'

const ValueCard = (props) => {
    const { valueNote }= props;
  return (
   <ValueCardContainer>
    <div></div>
    <p>{valueNote}</p>
   </ValueCardContainer>
  )
}

export default ValueCard