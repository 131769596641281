import styled from "styled-components";

export const StatementContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    min-height: 460px;
    height: fit-content;
    padding: 1rem 0;
    &.vision{
        flex-direction: row-reverse;
        padding:1rem 5rem;
        background-color: #EBEAEA;
    }
    &.mision{
        padding: 1rem 5rem;
    }

    @media (max-width: 768px){
       flex-direction: column;
       padding: 3rem;
       &.vision, &.mision{
        flex-direction: column;
        padding:3rem;
       }
    }
    @media (max-width: 480px){
       flex-direction: column;
       padding: 3rem 1rem;
       &.vision, &.mision{
        flex-direction: column;
        padding:3rem 1rem;
       }
    }
    `;

export const StatementImage = styled.img`
    width: 50%;
    height: auto;
    @media (max-width: 1200px){
      width: 50%;
      height: 500px;
     }
    @media (max-width: 768px){
      width: 100%;
      height: auto;
      border-radius:11px;
     }
`;

export const StatementContent = styled.div`
    width: 40%;
    height: 100%;
    a{
        margin-left: auto;
        border-radius: 30px;
        font-weight: 400;
    }
    @media (max-width: 1200px){
        width: 50%;
       }
    @media (max-width: 768px){
        width: 100%;
        text-align: center;
        a{
            margin: 0 auto;
        }
       }
`;

export const StatementTitle = styled.h1`
    color: ${({theme}) => theme.colors.primary};
    font-size: ${({theme}) => theme.fontSizes["5xl"]};
    text-transform: uppercase;
    position: relative;
    &::before{
        content: '';
        position: absolute;
        bottom:-10px;
        left: 0;  
        width: 120px;
        height: 8px;
        background-color: ${({theme}) => theme.colors.secondary};
        border-radius: 33px;
    }
    @media (max-width: 768px){
        &::before{
            left: 0; 
            right: 0; 
            margin-left: auto; 
            margin-right: auto; 
            width: 100px;
        }
    }
    @media (max-width: 480px){
        font-size: ${({theme}) => theme.fontSizes["4xl"]};
        &::before{
            width: 90px;
        }
    }
`;

export const StatementNote = styled.p`
   color: ${({theme}) => theme.colors.black};
   line-height: 20px;
   font-weight: 500;
   margin: 4rem 0 5rem;
   @media (max-width: 1200px){
    margin: 3rem 0 4rem;
   }
   @media (max-width: 768px){
    margin: 3rem 0 4rem;
    text-align: center;
   }
   @media (max-width: 480px){
    text-align: center;
   }
`;

