import React from 'react'
import ButtonLink from 'ui/components/ButtonLink'
import Statement from 'ui/components/Statement'
import { Icon } from '@iconify/react';
import { CompanyContainer, ValuesContainer, ValuesGrid, ValuesHeader} from './index.styled'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Company = ():JSX.Element => {
  return (
   <CompanyContainer>
    <Statement className='mision' src='/uploads/Frame1.png' title='Our Mission' note='Our mission is to provide our customers with ethically and sustainably sourced, high-quality agro-commodities, minor metals and base metals at competitive prices, while operating with integrity and adhering to the highest standards of ethics and corporate responsibility.'/>
    <Statement className='vision' src='/uploads/Frame2.png' title='Our Vision' note='Our vision is to be a leading, reputable and socially responsible global exporter of agro commodities, minor metals and base performance of contracts, commitment to sustainable sourcing, quality products, great prices, ethical business practices and corporate social responsibility.'/>
    <ValuesContainer>
        <ValuesHeader>COMPANY VALUES</ValuesHeader>
        <ValuesGrid>
            <div className='grid1'>
                <LazyLoadImage effect="blur" width="100%" src="/uploads/Team-work.png" alt="grid-img" />
            </div>
            <div className='grid2'>
            <LazyLoadImage effect="blur" width="100%" src="/uploads/Smileys.png" alt="grid-img" /> 
            </div>
            <div className='grid3'>
            <LazyLoadImage effect="blur" width="100%" src="/uploads/Quality.png" alt="grid-img" /> 
            </div>
        </ValuesGrid>
        <ButtonLink href='/about'> <Icon icon="uil:angle-right-b" color="white" width="24" /> <p> See More</p></ButtonLink>
    </ValuesContainer>
   </CompanyContainer>
  )
}

export default Company