import styled from "styled-components";

export const FooterContainer = styled.footer`
    width: 100%;
    padding: 4rem 0;
    background-color: ${({theme}) => theme.colors.white};

`;
export const FooterTop = styled.div`
    margin: 1rem 5rem 3rem;
    display: flex;
    justify-content: space-between;
    align-items: start;


    @media (max-width: 990px) {
        margin: 1rem 3rem 3rem;
    }
    @media (max-width: 480px) {
        margin: 1rem 1rem 3rem;
        flex-direction: column ;
        align-items: center;
        gap: 50px;
       }
`;

export const FooterTopLogo = styled.div`
    img{
        width: 140px;
    }

    @media (max-width: 768px) {
       width: 100px;
    }
    @media (max-width: 480px) {
       width: 80px;
    }
`;
export const FooterTopRight = styled.div`
    display: flex;
    align-items: start;
    gap: 100px;
    @media (max-width: 990px) {
        gap: 50px;
    }
    @media (max-width: 768px) {
        flex-direction: column ;
        align-items: end;
       }
    @media (max-width: 480px) {
        align-items: center;
        justify-content: center;
       }
`;

export const FooterRightLinks = styled.div`
    display: flex;
    gap: 50px;
    h3{
        font-weight: 700;
        font-size: 18px;
    }
    div{
        a{
            font-weight: 600;
            font-size: 20px;
            color: #1E1E1E;
            display: block;
            margin-bottom: 14px;
        }
    }
    @media (max-width: 480px) {
       div{
        a{
            font-size: 18px;
        }
       }
       }
`;

export const FooterRightSocials = styled.div`
    width: fit-content;
    color: #1E1E1E;
    h3{
        font-weight: 700;
        font-size: 20px;
    }
`;

export const SocialLinks = styled.div`
    display: grid;
    grid-template-columns:repeat(3, 1fr);
    width: 100%;
    align-items: center;
    margin-top: .5rem;
`

export const FooterBottom = styled.div``;

export const FooterBottomUp = styled.div`
    background: #EEEEEE;
    padding: 3rem 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: #1E1E1E;
    h3{
        font-weight: 600;
        font-size: 20px;
    }

    @media (max-width: 480px) {
      h3{
        font-size: 16px;
      }
        }
`;

export const FooterBottomHR = styled.div`
    width: 100%;
    height: 3px;
    background: #858181;
    border-radius: 46px;
    margin: 2rem 0 1rem;
`;

export const FooterBottomDown = styled.div`
    width: 100%;
    text-align: right;
    padding: 0 5rem 2rem;
        a{
            color: #1E1E1E;
            font-weight: 500;
            font-size: 16px;
        }

        @media (max-width: 990px) {
            padding: 0 3rem 2rem;
        }
        @media (max-width: 480px) {
            padding: 0 1rem 2rem;
            a{
                font-size: 14px;
            }
           }
`;