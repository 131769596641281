import React from 'react'
import { Typography } from 'ui/atoms/Typography'
import ServicesCarousel from 'ui/components/ServicesCarousel'
import { ServicesBottom, ServicesContainer, ServicesTop } from './index.styled'

const Services = ():JSX.Element => {
  return (
    <ServicesContainer>
        <ServicesTop>
            <Typography variant='h1'>
            WHAT DO YOU NEED
            </Typography>
        </ServicesTop>
        <ServicesBottom>
            <ServicesCarousel />
           
        </ServicesBottom>
    </ServicesContainer>
  )
}

export default Services