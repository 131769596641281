import React from 'react'
import { DropdownContainer } from './index.styled'

interface DropdownProps {
    children: React.ReactNode;
    onClick?: () => void;
}

const CustomDropdown = (props: DropdownProps):JSX.Element => {
    const {children, onClick} = props;
  return (
    <DropdownContainer onClick={onClick}>
        {children}
    </DropdownContainer>
  )
}

export default CustomDropdown