import styled from "styled-components";

export const AboutValueContainer = styled.section`
    width: 100%;
    background: #EEEEEE;
    padding: 5rem;
    @media (max-width: 990px){
        padding: 5rem 3rem;
    }
    @media (max-width: 480px){
        padding: 5rem 1rem;
    }
`;

export const AboutValueWrapper = styled.section`
    width: 100%;
    display: flex;
    gap: 50px;
    align-items: stretch;
    margin: 2rem 0;

    @media (max-width: 1100px){
        flex-direction: column-reverse;
        margin: 2rem auto;
        width: 70%;
    }
    @media (max-width: 768px){
        width: 100%;
    }
`;

export const ValueCardsContainer = styled.div`
    width: 50%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px;
    min-height: 100%;
    @media (max-width: 1100px){
        height:auto;
        margin: 2rem auto;
        width: 100%;
    }
    
}
`

export const ValueCardImage= styled.div`
    width: 50%;
    padding: 0.5rem;
    background: white;
    box-shadow: 0px 4px 30px rgba(206, 147, 8, 0.2);
    border-radius: 15px;
    min-height: 100%;
    img{
        border-radius: 11px;
        width: 100%;
    }
    @media (max-width: 1100px){
        height:auto;
        margin: 2rem auto;
         width: 100%;
    }
    
`

