import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import React from 'react';
import SwiperCore, { A11y, Autoplay, EffectCoverflow, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { HeroContainer } from './index.styled';
import { BannerInfo } from './index.styled';
import ButtonLink from 'ui/components/ButtonLink';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

SwiperCore.use([Pagination, A11y, Autoplay, EffectCoverflow]);
const Hero = ():JSX.Element => {
  return (
   <HeroContainer>
        <Swiper
        effect={'coverflow'}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: false
        }}
        spaceBetween={50}
        slidesPerView={1}
        pagination={{ clickable: true }}
        initialSlide={0}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop
        // effect="creative"
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}>
        <SwiperSlide>
          <LazyLoadImage src="/uploads/HeroBackground1.png"  effect="blur" alt="img"  className="image" width="100%"  placeholderSrc="/uploads/HBP1.png"/>
        {/* <img className="image" src="/uploads/HeroBackground1.png"  alt="img" /> */}
          <BannerInfo>
            <h1>Bridging borders,building relationships  </h1>
            <ButtonLink href='/contact'>
             <p> Get Started</p>
            </ButtonLink>
            </BannerInfo>
        </SwiperSlide>
        <SwiperSlide>
        <LazyLoadImage src="/uploads/HeroBackground2.png"  effect="blur" alt="img"  className="image" width="100%"  placeholderSrc="/uploads/HBP2.png" />
          <BannerInfo>
            <h1>Exporting excellence, worldwide</h1>
            <ButtonLink href='/contact'>
             <p> Get Started</p>
            </ButtonLink>
            </BannerInfo>
        </SwiperSlide>
        <SwiperSlide>
        <LazyLoadImage src="/uploads/HeroBackground3.png"  effect="black-and-white" alt="img"  className="image" width="100%"  placeholderSrc="/uploads/HBP3.png"/>
          <BannerInfo>
            <h1>From our fields to your doorstep</h1>
            <ButtonLink href='/contact'>
             <p> Get Started</p>
            </ButtonLink>
            </BannerInfo>
        </SwiperSlide>
        .
      </Swiper>
   </HeroContainer>
  )
};

export default Hero