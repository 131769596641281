import styled from "styled-components";

export const ContactFormContainer = styled.section`
    background: #EEEEEE;
    padding: 6rem 0 3rem;
    width: 100%;
    h4{
        color: black;
        margin: 0 auto 2rem;
        text-align:center;
    }
`;

export const Form = styled.form`
    width: 100%;
    margin-top: 2rem;
    button{
        margin-left: auto;
    }
`;

export const InputGroup = styled.div`
    width: 100%;
    margin-bottom: 2rem;
    input, textarea{
        width: 100%;
        padding: 0.5rem 0;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 2px solid #D9D9D9;
        outline: none;
        font-family: ${({theme}) => theme.fonts.primary};
        &::placeholder{
            color: black;
            font-weight: 500;
            font-family: ${({theme}) => theme.fonts.primary}
        }
        &.error {
            border-bottom: 1px solid red;
        }
    }
    .errorText{
        color: red;
        font-size: 10px;
    }
`;