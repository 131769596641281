import React, { useState } from 'react'
import CustomHero from 'ui/components/CustomHero'
import MobileNav from 'ui/components/MobileNav'
import Nav from 'ui/components/Nav'
import Footer from 'ui/widgets/Footer'
import { ProfileContainer, ProfileDivider } from './index.styled'

const Profile = () => {
    const [open, setOpen] = useState(false)
  return (
    <ProfileContainer>
         {!open && <Nav setOpen={setOpen} open={open} />}
         <MobileNav open={open}  setOpen={setOpen}/>
           <CustomHero className="ethos-banner" className2="ethos-banner2" src="/uploads/ProfileBanner.png" bannerTitle="Our Ethos" bannerNote="At TACL Global, we are committed to delivering high-quality agro commodities, minor metals, and base metals to our customers around the world. We believe in operating with integrity and transparency, adhering to the highest ethical and environmental standards, and contributing to the communities where we operate.
            
            We are constantly exploring new ways to improve our operations, products and customer experience. We invest in technology and process innovation to improve our efficiency, reduce waste, enhance our products' quality and deliver great value to our customers. We are proud of our commitment to corporate responsibility, innovation and client satisfaction, and we are excited about the future of our business.
"/>
          <ProfileDivider />
           <Footer />
    </ProfileContainer>
  )
}

export default Profile 