import styled from "styled-components"

export const HeroContainer = styled.section`
max-height:800px;
width: 100%;
.swiper{
    max-height: 800px;
    .swiper-slide-active {
        height: 100%;
        position: relative;
        .image {
          opacity: 1;
          width: 100%;
          max-height: 800px;
        }
      }

      .swiper-wrapper {
        margin-bottom: 2rem;
      }
      .swiper-pagination{
        text-align: left;
        margin: 0 5rem;
        bottom: 50px;
        .swiper-pagination-bullet {
            background-color: white;
            opacity: 1;
            width: 15px;
            height: 15px;
          }
          .swiper-pagination-bullet-active {
           background-color: ${({theme}) => theme.colors.secondary};
          }
      }
}
@media (max-width: 1400px){
    .swiper{
        .swiper-pagination {
            bottom: 100px;
        }
    }
  }
  @media (max-width: 990px){
    .swiper{
      .swiper-slide-active{
        .image{
            height: 70vh;
        }
      }
      .swiper-pagination {
        margin: 0 3rem;
      }
    }
  }
  @media (max-width: 480px){
    .swiper{
        .swiper-pagination {
            bottom: 100px;
            margin: 0 1rem;
            .swiper-pagination-bullet{
                width: 10px;
                height: 10px;
            }
        }
    }
  }
 `;

 export const BannerInfo = styled.div`
  width: 35%;
  text-align: left;
  position: absolute;
  z-index: 1;
  bottom: 100px;
  margin: 0 5rem;
  h1{
    font-size: ${({theme}) => theme.fontSizes["6xl"]};
    color: ${({theme}) => theme.colors.white};
    margin-bottom: ${({theme}) => theme.sizes[24]};
    text-transform: uppercase;
    font-weight: ${({theme}) => theme.fontWeights.extraBold};
  }
  @media (max-width: 1400px){
    width: 40%;
    bottom: 150px;
    h1{
        font-size: ${({theme}) => theme.fontSizes["5xl"]};
        margin-bottom: ${({theme}) => theme.sizes[16]};
    }
  }
  @media (max-width: 990px){
    width: 50%;
    margin: 0 3rem;
    h1{
        font-size: ${({theme}) => theme.fontSizes["5xl"]};
        margin-bottom: ${({theme}) => theme.sizes[12]};
    }
  }
  @media (max-width: 768px){
    width: 55%;
    bottom: 100px;
    h1{
        font-size: ${({theme}) => theme.fontSizes["5xl"]};
        margin-bottom: ${({theme}) => theme.sizes[8]};
    }
    button{
        padding: 0.5rem 1.5rem;
        font-size: ${({theme}) => theme.fontSizes.xs}
    }
  }
  @media (max-width: 480px){
    width: 90%;
    margin: 0 1rem;
    bottom: 120px;
    h1{
      font-size: 32px;
      margin-bottom: ${({theme}) => theme.sizes[8]};
  }
  }
 `;