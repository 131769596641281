import styled from "styled-components";

export const CardContainer = styled.div`
background: #FFFFFF;
box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
border-radius: 30px;
max-width: 800px;
margin: 0 auto;
width: 100%;
height: fit-content;
padding: 2rem 5rem;
@media (max-width: 990px){
    padding: 2rem 3rem;
}
@media (max-width: 480px){
    padding: 2rem 1rem;
}
`;