import styled from "styled-components";

export const DropdownContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
     &:hover{
        div{
            display: flex;
        }
        p,svg{
            opacity: 0.7;
        }
     }
`;